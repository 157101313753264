export enum PAGES {
  LOGIN = '/login',
  INFO = '/info',
  AWAITING_APPROVAL = '/awaiting-approval',
  PROJECTS = '/projects',
  USER_MANAGEMENT = '/user-management',
  HELP_CENTER = '/help-center',
  DOCUMENTS = '/documents',
  TERMS = '/documents/terms',
  PRIVACY_POLICY = '/documents/policy',
  BACKROOM = '/backroom',
  GLS_TIKTOK_ORGANIC = '/gls/tiktok-organic',
  GLS_FB_ADS = '/gls/fb-ads',
  GLS_FACEBOOK_PUBLIC = '/gls/facebook-public',
  GLS_FB_INSIGHTS = '/gls/fb-insights',
  GLS_GOOGLE_ANALYTICS = '/gls/google-analytics',
  GLS_GOOGLE_ADS = '/gls/google-ads',
  GLS_TIKTOK_PUBLIC = '/gls/tiktok-public',
  GLS_TIKTOK_ADS = '/gls/tiktok-ads',
  GLS_INSTAGRAM_PUBLIC = '/gls/instagram-public',
  GLS_INSTAGRAM_INSIGHTS = '/gls/instagram-insights',
  DASHBOARD_MANAGEMENT = '/dashboard-management',
}

export const PREFERRED_COMPANY_COOKIE_NAME = 'preferredCompany';

export const SOURCE_CONNECTED_MESSAGE = encodeURIComponent(
  `Data source successfully connected. You can close this tab.`
);

export const SOURCE_WAS_NOT_CONNECTED_MESSAGE = encodeURIComponent(
  `Data source was not connected.`
);

export const DEFAULT_PAGINATION = {
  size: 20,
  number: 1,
};
